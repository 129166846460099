import React, {SetStateAction, useEffect, useState} from 'react';
import './App.css';
import {useWindowSize} from "./utils/useWindowSize";
import {calcDelay, calcThreeSqrt, floorN} from "./utils/calcFunction";

function App() {
    const [width, height] = useWindowSize();
    const [select, setSelect] = useState(0);

    const [sp1_height, setSp1_height] = useState("0");
    const [sp2_height, setSp2_height] = useState("0");
    const [sp_distance, setSp_distance] = useState("0");
    const [temp, setTemp] = useState("15");
    const [delay, setDelay] = useState(0);

    // @typescript-eslint/no-explicit-any
    const selector = (): [string, React.Dispatch<SetStateAction<string>>] => {
        switch (select) {
            case 0:
                return [sp1_height, setSp1_height];
            case 1:
                return [sp2_height, setSp2_height];
            case 2:
                return [sp_distance, setSp_distance];
            case 3:
                return [temp, setTemp];
            default:
                return [sp1_height, setSp1_height];
        }
    }

    useEffect(() => {
        [...Array(4)].map((_, v) => {
            const disp = document.getElementById(`display-${v}`);
            if (disp == null) return;
            disp.removeAttribute('style');
        });
        const s = setTimeout(() => {
            const d = document.getElementById(`display-${select}`);
            if (d == null) return;
            d.style.background = "gray";
            clearTimeout(s);
        }, 10);
    }, [select]);

    const click = (id: number) => {
        const [s, ss] = selector();
        if (id == 11 && s.length > 0) {
            ss(s.slice(0, -1));
            return;
        }
        const num = id == 10 ? "." : String(id);
        if (s.startsWith("0")) {
            ss(num);
        } else {
            ss(s + num);
        }
    }

    useEffect(() => {
        if (temp == "0") return;
        const sp1_h = Number(sp1_height);
        const sp2_h = Number(sp2_height);
        const sp_d = Number(sp_distance);
        const t = Number(temp);
        let res: number;
        if (sp1_h != sp2_h) {
            const h = Math.abs(sp1_h - sp2_h);
            const r = floorN(calcThreeSqrt(sp_d, h), 2);
            console.log(h, r);
            res = floorN(calcDelay(r, t), 2);
        } else {
            console.log(calcDelay(sp_d, t))
            res = floorN(calcDelay(sp_d, t), 2);
        }
        setDelay(res);

    }, [sp1_height, sp2_height, sp_distance, temp]);

    return (
        <div className="App" style={{height: height, width: width}}>
            <div className="container">
                <div className="row">
                    <div className="display">
                        <div>
                            <div className="c-row d-disp">
                                <span className="d-text">SP1 Height:</span>
                                <span className="d-text d-result" id="display-0"
                                      onClick={() => setSelect(0)}>{`${sp1_height}m`}</span>
                            </div>
                            <div className="c-row d-disp">
                                <span className="d-text">SP2 Height:</span>
                                <span className="d-text d-result" id="display-1"
                                      onClick={() => setSelect(1)}>{`${sp2_height}m`}</span>
                            </div>
                            <div className="c-row d-disp">
                                <span className="d-text">SP Distance:</span>
                                <span className="d-text d-result" id="display-2"
                                      onClick={() => setSelect(2)}>{`${sp_distance}m`}</span>
                            </div>
                            <div className="c-row d-disp">
                                <span className="d-text">Temp:</span>
                                <span className="d-text d-result" id="display-3"
                                      onClick={() => setSelect(3)}>{`${temp}℃`}</span>
                            </div>
                            <div className="c-row d-disp">
                                <span className="d-text">Delay:</span>
                                <span className="d-text d-result" id="display-res">{`${delay}ms`}</span>
                            </div>
                        </div>
                    </div>
                    <div className="control">
                        <div className="c-container">
                            {
                                [[7, 8, 9], [4, 5, 6], [1, 2, 3], [0, 10, 11]].map((r, i) =>
                                    <div className="c-row" key={i}>
                                        {
                                            r.map(c => <div className="c-col" key={c}>
                                                {
                                                    <button className="c-btn" onClick={() => click(c)}>{
                                                        c == 10 ? "." : c == 11 ? "back" : c
                                                    }
                                                    </button>
                                                }
                                            </div>)
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
